import React, { useState, useEffect } from 'react';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { TextDesignElement, textDesignElementHasFillTarget } from '../../../../app/domain/Design/TextDesignElement';
import { textFillTarget } from '../../../../app/domain/Design/TextFillTarget';
import { findFontByName } from '../../../../app/domain/Font/Font';
import { setFillTarget, setFont, SetFontOptions, setText, SetTextOptions } from '../../../../app/initApp';
import Radio from '../../misc/field/Radio';
import ThumbSelectInput from '../../misc/field/ThumbSelectInput';
import DecorationAreaPanel from '../../steps/customize/DecorationAreaPanel';
import FillEditor from './FillEditor';
import OutlineManager from './outline/OutlineManager';
import Field from '../../misc/field/Field';

// Styles
import styles from './CAEditor.module.css';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  decorationArea: DecorationArea;
  nameFonts: any;
  allFonts: any;
  hiddenFontsIds: string[];
}

const CAEditor = (props: Props) => {
  const aKey = 'A';
  const cKey = 'C';

  const decorationArea = props.decorationArea;
  const nameFonts = props.nameFonts;
  const textDesignElement = decorationArea.DesignElement as TextDesignElement;
  const activeFillTarget = props.activeFillTarget;
  const fillIsSelected = activeFillTarget && textDesignElementHasFillTarget(textDesignElement, activeFillTarget);

  let fontId = textDesignElement.fontId;

  if (!fontId && textDesignElement.fontFamily) {
    const font = findFontByName(textDesignElement.fontFamily, nameFonts);
    if (font) {
      fontId = font.value;
    }
  }

  const [selectedText, setSelectedText] = useState(textDesignElement.text);

  useEffect(() => {
    setSelectedText(textDesignElement.text);
  }, [textDesignElement.text]);

  function handleRadioChange(value: string) {
    if (value !== selectedText) {
      setSelectedText(value);
      onTextChange(textDesignElement, value);
    }
  }

  return (
    <div className="ca-editor">
      <DecorationAreaPanel
        decorationArea={decorationArea}
        activeId={decorationArea.Id}
        activeFillTarget={activeFillTarget}
      >
        {/* C or A? */}
        <Field className={styles.field}>
          <div className={styles.radioOptions}>
            <label>Preview</label>
            <Radio
              checked={selectedText === cKey}
              className={styles.radioField}
              label="C"
              onClick={() => handleRadioChange(cKey)}
            />
            <div className={styles.orLabel}>Or</div>
            <Radio
              checked={selectedText === aKey}
              className={styles.radioField}
              label="A"
              onClick={() => handleRadioChange(aKey)}
            />
          </div>
        </Field>
        <ThumbSelectInput
          className={styles.field}
          handler={(value, field, option) => {
            onFontChange(textDesignElement, value, option.data.fontName, props.allFonts);
          }}
          label="Font Style"
          options={nameFonts}
          value={fontId}
          hiddenIds={props.hiddenFontsIds}
        />
        <FillEditor
          brush={textDesignElement.brush}
          label="Fill Color"
          onClick={() => {
            setFillTarget({
              fillTarget: textFillTarget(textDesignElement),
            });
          }}
          selected={fillIsSelected}
        />
        <OutlineManager
          activeFillTarget={activeFillTarget}
          allFonts={props.allFonts}
          textDesignElement={textDesignElement}
        />
      </DecorationAreaPanel>
    </div>
  );
};

function onFontChange(designElement: TextDesignElement, fontId: string, fontName: string, fontConfigs: any) {
  setFont({
    designElement,
    fontConfigs,
    fontId,
    fontName,
  } as SetFontOptions);
}

function onTextChange(designElement: TextDesignElement, text: string) {
  setText({
    designElement,
    text,
  } as SetTextOptions);
}

export default CAEditor;
